<template>
    <div>
        <overlay v-if="$store.state.carsTimeOut" :newSearch="getCars" />
        <div class="car-search-bg">
            <v-container>
                <v-row justify="center" class="ma-5" align="center">
                  <v-card class="pa-5 pt-10 rounded-xl">
                    <carSearch @addCars="getCars"></carSearch>
                  </v-card>
                </v-row>
            </v-container>
        </div>
        <v-container fluid>
            <v-row justify="center" align="center" style="height: 60vh" v-if="!cars && loaded">
              <div>
                <v-img max-width="200" class="blink-2" src="../static/logo-dark.png"></v-img>
              </div>
            </v-row>
            <v-row v-if="cars && !loaded">
                <v-col cols="12" sm="3" md="2">
                  <carFilter @changed="filter"/>
                </v-col>
                <v-col cols="12" sm="9" md="10">
                  <p class="body-1 primary--text font-weight-bold">{{cars.cars.length}} available from {{formatDate(cars.pickUpDate)}} at {{cars.pickUpTime}} to {{formatDate(cars.returnDate)}} at {{cars.returnTime}}</p>
                  <p v-if="criteria" class="body-2 primary--text">From ${{criteria.prices[0]}} To ${{criteria.prices[1]}}, {{criteria.cat}}, {{criteria.seats}} seats by {{criteria.vendors}}</p>
                  <carCard :cars="cars"></carCard>
                </v-col>
            </v-row>
            <p v-if="!cars && !loaded" class="body-1 font-weight-bold primary--text text-center mt-5">Sorry, No cars match your search .</p>
        </v-container>
    </div>
</template>

<script>
import carCard from '../components/carCard'
import carSearch from '../components/carSearch'
import overlay from '../components/overlay'
import carFilter from '../components/carFilter'
import { carsSearch, headersNoAuth } from '../links'

export default {
  title: '-Cars Search Results',
  components: {
    carCard,
    carSearch,
    overlay,
    carFilter
  },
  data () {
    return {
      cars: null,
      criteria: null,
      snackbar: false,
      color: '',
      text: '',
      loaded: true
    }
  },
  computed: {
    carsStore () {
      return this.$store.state.cars
    }
  },
  watch: {
    carsStore (newVal) {
      this.cars = newVal
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    filter (cars, criteria) {
      this.cars.cars = [...cars]
      this.criteria = criteria
    },
    getCars (term) {
      this.$store.dispatch('removeCarsResults')
      this.loaded = true
      this.$http.get(carsSearch(term), { headers: headersNoAuth() }).then(response => {
        if (response.body.status === false) {
          this.snackbar = true
          this.color = 'error'
          this.text = response.body.message
          this.loaded = false
        } else {
          this.loaded = false
          this.$store.dispatch('setCars', response.body.data)
          this.$store.dispatch('setCarsTimeOut', false)
          this.$store.dispatch('setCarsSearchTimeOutFunction', setTimeout(() => {
            this.$store.dispatch('setCarsTimeOut', true)
          }, 20 * 60 * 1000)
          )
          localStorage.setItem('carsTerm', term)
          this.cars = this.$store.state.cars
        }
      }, () => {
        this.snackbar = true
        this.color = 'error'
        this.text = 'No cars match your search'
        this.loaded = false
      })
    }
  },
  created () {
    window.scrollTo(0, 0)
    let data
    if (this.$route.params.term) data = this.$route.params.term
    else data = localStorage.getItem('carsTerm')
    this.getCars(data)
  }
}
</script>

<style scoped>
    .car-search-bg {
        height: auto;
        min-height: calc(40%);
        background: url('http://dev-booking-engine.fare33.com/images/assets/car.jpeg');
        background-size: cover;
        background-position: center center;
    }
    .car-search-bg .container {
      padding-top: 25px;
    }
</style>
